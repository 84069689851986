<section class="section bg-light">
  <div class="container bring-to-front pb-0">
    <div class="section-heading text-center w-75 mx-auto">
      <h2>Pourquoi choisir 4CALL?</h2>
      <p class="lead text-secondary">
        Dynamique et réactive, 4CALL travaille en "sur-mesure" avec chacun de
        ses clients, adaptant sa prestation et sa relation client.
      </p>
    </div>

    <div class="shadow bg-contrast p-5 rounded">
      <!--<ngb-tabset type="tabs tabs-clean slide d-inline-flex mb-4" [destroyOnHide]="false">

        <ngb-tab>
          <ng-template ngbTabTitle><span class="bold text-uppercase">Designers</span></ng-template>
          <ng-template ngbTabContent>
            <div class="row gap-y">
              <div class="col-lg-6">
                <h2 class="bold mb-4">
                  Engaging designers with tons of design elements
                </h2>
                <p class="lead text-secondary">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Culpa cumque deleniti facere ipsa laborum saepe, tenetur
                  ullam? Consequatur, deserunt eaque facilis hic, illo
                  laboriosam minima quae quia temporibus totam vel.
                </p>
              </div>
              <div class="col-lg-6">
                <figure class="px-2">
                  <img src="assets/img/screens/designer.png" class="img-responsive shadow rounded" alt="..." />
                </figure>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>-->
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink><span class="bold text-uppercase">CLIENT</span></a>
          <ng-template ngbNavContent>
            <div class="row gap-y mt-3">
              <div class="col-lg-6">
                <h2 class="bold mb-4 text-center">
                  Accompagnement « sur-mesure »
                </h2>
                <p class="lead text-secondary text-center">
                  4CALL, Opérateur télécoms pour entreprises accompagne ses partenaires dans leurs projets de
                  transformation digitale, de délocalisation ou encore d’externalisation de services…
                </p>
              </div>
              <div class="col-lg-6">
                <figure class="px-2">
                  <img src="assets/img/screens/1.jpeg" class="img-responsive shadow rounded" alt="..." />
                </figure>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink><span class="bold text-uppercase">SERVICE</span></a>
          <ng-template ngbNavContent>
            <div class="row gap-y mt-3">
              <div class="col-lg-6">
                <h2 class="bold mb-4 text-center">
                  Des services innovants
                </h2>
                <p class="lead text-secondary text-center">
                  Depuis plus de 3 ans 4CALL s’est positionné comme étant l'un des acteurs incontournables de la
                  relation client et met à disposition des professionnels des solutions innovantes : MVNO, Trunk SIP,
                  Numéro SDA/SVA, CRM de gestion multicanal, API & Serveurs, Développement WEB !
                </p>
              </div>
              <div class="col-lg-6">
                <figure class="px-2">
                  <img src="assets/img/screens/2.jpeg" class="img-responsive shadow rounded" alt="..." />
                </figure>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink><span class="bold text-uppercase ">SUPPORT</span></a>
          <ng-template ngbNavContent>
            <div class="row gap-y mt-3">
              <div class="col-lg-6">
                <h2 class="bold mb-4 text-center">
                  Un support réactif
                </h2>
                <p class="lead text-secondary text-center">
                  Afin que vos questions trouvent toujours une réponse et que vous puissiez nous contacter
                  facilement, nous mettons à votre disposition différents moyens sûrs et rapides.
                </p>
              </div>
              <div class="col-lg-6">
                <figure class="px-2">
                  <img src="assets/img/screens/3.jpeg" class="img-responsive shadow rounded" alt="..." />
                </figure>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink><span class="bold text-uppercase">QUALITE/PRIX</span></a>
          <ng-template ngbNavContent>
            <div class="row gap-y mt-3">
              <div class="col-lg-6">
                <h2 class="bold mb-4 text-center">
                  Conditions avantageuses
                </h2>
                <p class="lead text-secondary text-center">
                  Parce que nous travaillons avec des professionnels et que nous les comprenons, nous
                  accordons une très grande importance à la qualité de nos services. Quelque soit le service
                  proposé, notre politique est de toujours vous faire bénéficier des meilleures conditions tarifaires.
                </p>
              </div>
              <div class="col-lg-6">
                <figure class="px-2">
                  <img src="assets/img/screens/4.jpeg" class="img-responsive shadow rounded" alt="..." />
                </figure>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</section>