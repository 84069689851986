<footer class="site-footer section">
  <div class="container pb-3">
    <div class="row gap-y text-center text-md-left">
      <div class="col-md-4 mr-auto">
        <img src="assets/img/logo_4call.png" alt="" class="logo" />

        <p>
          4CALL est un opérateur télécom qui participe à la croissance des
          entreprises TPE, PME en leur fournissant une large gamme de services
          dans le secteur de la télécommunication… .
        </p>
      </div>

      <div class="col-md-3">
        <h6 class="py-2 bold">Information</h6>

        <nav class="nav flex-column">
          <a class="nav-item py-2" [routerLink]="['/legals']">Mentions Legales</a>
          <a class="nav-item py-2" [routerLink]="['/terms']">Politique de confidentialité</a>
          <a class="nav-item py-2" [routerLink]="['/cgu']">CGU</a>
        </nav>
      </div>


      <div class="col-md-3">
        <h6 class="py-2 bold">Addresse</h6>

        <nav class="nav flex-column">
          <p class="nav-item py-2">21 Rue Marcel Carne, 34970 Lattes FRANCE</p>
        </nav>
      </div>
    </div>

    <hr class="mt-5" />
    <div class="row small align-items-center">
      <div class="col-md-6">
        <p class="mt-2 mb-md-0 text-secondary text-center text-md-left">
          © 2020, www.4call.fr, tous droits réservés - 09 88 19 41 74 (appel non surtaxé). Opérateur télécom (licence
          L33-1) déclaré à l'ARCEP (4CLL).
        </p>
      </div>

      <div class="col-md-5">
        <nav class="nav justify-content-center justify-content-md-end">
          <a href="#" class="btn btn-circle btn-sm btn-secondary mr-3 op-4">
            <fa-icon [icon]="fa.faFacebook"></fa-icon>
          </a>
          <a href="#" class="btn btn-circle btn-sm btn-secondary mr-3 op-4">
            <fa-icon [icon]="fa.faTwitter"></fa-icon>
          </a>
          <a href="#" class="btn btn-circle btn-sm btn-secondary op-4">
            <fa-icon [icon]="fa.faInstagram"></fa-icon>
          </a>
        </nav>
      </div>
    </div>
  </div>
</footer>