<nav class=" navbar navbar-expand-lg navigation sidebar-right fixed-top" [ngClass]="{
    'navbar-expanded': navbarExpanded,
    'navbar-sticky': navbarSticky,
    'dark-link': darkLinks
  }">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>

    <a href="#main" class="navbar-brand">
      <img src="assets/img/logo_4call.png" alt="Dashcore" class="logo logo-sticky"
        *ngIf="useOnlyDarkLogo; else logos" />
      <ng-template #logos>
        <img src="assets/img/logo_4call.png" alt="Dashcore" class="logo logo-sticky d-block d-md-none" />
        <img src="assets/img/logo_4call.png" alt="Dashcore" class="logo d-none d-md-block" />
      </ng-template>
    </a>

    <div class="collapse navbar-collapse ml-auto col-md-8">
      <ng-content></ng-content>
    </div>
  </div>
</nav>