import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-startup-features',
  templateUrl: './startup-features.component.html',
  styleUrls: ['./startup-features.component.scss']
})
export class StartupFeaturesComponent implements OnInit {
  elements = [
    { icon: 'phone', title: 'Téléphonie Fixe PRO' },
    { icon: 'user', title: 'Standard Téléphonique' },
    { icon: 'smartphone', title: 'Téléphonie Mobile PRO' },
    { icon: 'bar-chart', title: 'CRM en Ligne' },
    { icon: 'message-circle', title: 'Numéro Spécial & SMS+' },
    { icon: 'hard-drive', title: 'API & Serveurs' }
  ];
  content= ['Découvrez nos différents forfaits fixes pour professionnels, faites des économies sur vos appels au quotidien.',
  'Ne perdez plus d\'appels et professionnalisez votre accueil avec votre standard virtuel évolutif et riche en fonctionnalités... .',
'4CALL en tant que MVNO vous propose des forfaits mobile professionnels de qualité sur les meilleurs réseaux mobile de France.',
'Intégration de la téléphonie 4CALL à vos applicatifs métiers... .',
'Numéro 08 et SMS+ en 3 clics avec reversement et sans engagement.',
'Interfaces de Programmation d\'Applications pour développeurs.']

  constructor() {
    
  }

  ngOnInit() {}
}
