<section class="section {{ background ? 'bg-' + background : '' }} {{
    edge ? 'edge ' + edge : ''
  }}">
  <div class="container pt-5">
    <div class="d-flex align-items-center flex-column flex-md-row">
      <div class="text-center text-md-left">
        <p class="light mb-0 text-primary lead">Prêt à nous rejoindre ?</p>
        <h2 class="mt-0">Commander votre devis</h2>
      </div>
      <a href="https://hamdiharaketi.typeform.com/to/pkBV4ul5" target="_blank"
        class="btn btn-primary btn-rounded mt-3 mt-md-0 ml-md-auto">Devis en ligne</a>
    </div>
  </div>
</section>