<section id="features" class="section">
  <div class="container pb-5">
    <div class="section-heading mb-6 text-center">
      <span class="rounded-pill shadow-box badge badge-info bold py-2 px-4">Nous ajoutons de la valeur à vos
        communications…</span>
      <h2 class="mt-3">Nos offres 100% cloud</h2>
    </div>

    <div class="row gap-y text-center">
      <div class="col-md-4 py-4 rounded shadow-hover" *ngFor="let element of elements; index as i">
        <dc-feather [name]="element.icon" [iconClass]="'stroke-primary mb-3'"></dc-feather>
        <h5 class="bold">{{ element.title }}</h5>
        <p>
          {{content[i]}}
        </p>
      </div>
    </div>
  </div>
</section>