import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/services/shell.service';
import { StartupComponent } from './pages/startup/components/startup/startup.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', component: StartupComponent },
    {
      path: 'legals',
      loadChildren: () =>
        import('./pages/legals/legals.module').then(m => m.LegalsModule)
    },
    {
      path: 'cgu',
      loadChildren: () =>
        import('./pages/cgu/cgu.module').then(m => m.CguModule)
    },
    {
      path: 'devis',
      loadChildren: () =>
        import('./pages/devis/devis.module').then(m => m.DevisModule)
    },
    {
      path: 'about',
      loadChildren: () =>
        import('./pages/about/about.module').then(m => m.AboutModule)
    },
    {
      path: 'contact',
      loadChildren: () =>
        import('./pages/contact/contact.module').then(m => m.ContactModule)
    },
    {
      path: 'home',
      loadChildren: () =>
        import('./pages/startup/startup.module').then(m => m.StartupModule)
    },
    {
      path: 'terms',
      loadChildren: () =>
        import('./pages/terms/terms.module').then(m => m.TermsModule)
    }
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
