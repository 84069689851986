import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-startup-why-dashcore',
  templateUrl: './startup-why-dashcore.component.html',
  styleUrls: ['./startup-why-dashcore.component.scss']
})
export class StartupWhyDashcoreComponent implements OnInit {
  active = 1;
  constructor() {}

  ngOnInit() {}
}
