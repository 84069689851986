<dc-startup-heading class="mb-1"></dc-startup-heading>

<dc-startup-features id="services" class="m-1"></dc-startup-features>
<!--<dc-slider-bring-to-life></dc-slider-bring-to-life>-->
<dc-startup-why-dashcore></dc-startup-why-dashcore>
<!--<dc-startup-videos></dc-startup-videos>-->
<dc-cta1 [edge]="'top-left'" [background]="'contrast'" class="m-1"></dc-cta1>
<!--<dc-trust-us></dc-trust-us>-->
<!--<dc-developer-designer></dc-developer-designer>-->

<dc-footer1></dc-footer1>